<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <h4 style="text-align: center; margin-top: 10px">Insurance</h4>
        <form v-on:submit.prevent="submit()">
          <div class="form-group">
            <label for="tracking">Tracking:</label>
            <input
              type="text"
              id="tracking"
              v-model="tracking"
              class="form-control"
              required
            />
          </div>

          <div class="form-group">
            <label for="value">Value:</label>
            <input
              type="number"
              step="0.01"
              id="value"
              v-model="value"
              class="form-control"
              required
            />
          </div>

          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
      </div>
    </div>

    <table class="table mt-4" style="text-align: center">
      <thead>
        <tr>
          <th>ID</th>
          <th>Tracking</th>
          <th>Value</th>
          <th>Order</th>
        </tr>
      </thead>
      <tbody >
        <tr v-for="insurance in insurances" :key="insurance.id">
          <td>{{ insurance.id }}</td>
          <td>{{ insurance.tracking_number }}</td>
          <td>{{ insurance.value }}</td>
          <td><b-link @click="routeToOrder(insurance.order_id)">{{ insurance.order_id }}</b-link></td>
        </tr>
        <!-- Add insurance data here -->
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tracking: "",
      value: "",
      insurances: [],
    };
  },
  methods: {
    async submit() {
      console.log(this.tracking);
      console.log(this.value);
      try {
        await this.$store.dispatch("insertInsurance", {
          tracking: this.tracking,
          value: this.value,
        });
        this.$swal.fire({
          icon: "success",
          title: "Insurance added",
          showConfirmButton: true,
          timer: 1500,
        });
        this.getInsurances();
        this.tracking = "";
        this.value = "";
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          icon: "error",
          title: error,
        });
      }
    },
    async getInsurances() {
      try {
        await this.$store.dispatch("allInsurance");
        this.insurances = this.getAllInsurances;
      } catch (error) {
        console.log(error);
        alert(error);
      }
    },
    routeToOrder(orderId) {
      // this.$router.push(`/orders/${orderId}`);
      window.open(`https://${window.location.host}/orders/${orderId}`, '_blank');
    },
  },
  created() {
    this.getInsurances();
  },
  computed: {
    ...mapGetters(["getAllInsurances"]),
  },
};
</script>
